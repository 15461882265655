<template>
  <PixelWrapper>
    <mp-box
      v-if="!isInitiated && $auth.loggedIn"
      class="centered-item w-100 qon-loading"
    >
      <mp-loader class="page-loader" />
    </mp-box>
    <mp-box v-else class="mp-body">
      <!-- <vue-element-loading
        :active="loadingbar"
        :duration="'0.5'"
        :size="'68'"
        spinner="bar-fade-scale"
        color="#0274f5"
      /> -->
      <!-- <mp-box id="qon-announcement" as="div"> -->
      <BannerMaintenance
        v-if="bannerMaintenance.isShow"
        :banner-maintenance="bannerMaintenance || {}"
        @set-banner-height="setBannerHeight"
      />
      <BannerUserStatus
        v-if="bannerUserStatus.isShow"
        :banner-user-status="bannerUserStatus"
        :billing-info="billing_info || {}"
        @set-banner-height="setBannerHeight"
        @on-close-banner-status="onCloseBannerStatus"
        @redirect-to-customer-care="redirectToCustomerCare"
      />
      <BannerCreditBalance
        v-if="bannerCreditBalance.isShow"
        :is-chatbot="bannerCreditBalance.isChatbot"
        :banner-credit-balance="bannerCreditBalance || {}"
        :billing-info="billing_info || {}"
        @set-banner-height="setBannerHeight"
        @on-click-banner="onClickBanner"
      />
      <mp-broadcast
        v-if="bannerInformationNewPrice.isShow"
        variant="information"
        icon-name="broadcast"
        content="Starting 1 Nov 2024, service conversations in WhatsApp conversations quota will be free of charge. Information related to service conversations will be removed."
        text-link="Learn more"
        is-closable
        @click-close-button="handleCloseBannerInformation"
        @click-text-link="handleClickLinkBannerInformation"
      />
      <!-- Banner maintenance -->
      <!-- <mp-box
          v-if="bannerInfo.isMaintenance"
          id="qon-announcement-maintenance"
          as="div"
          bg-color="ice.100"
          px="3"
          py="2"
        >
          <mp-flex class="centered-grid-item">
            <mp-icon
              name="warning-triangle"
              color="black"
              variant="fill"
              mr="3"
            />
            <mp-text v-dompurify-html="bannerInfo.output" as="span" />
          </mp-flex>
        </mp-box> -->

      <!-- Banner status user trial/active/grace -->
      <!-- <mp-box
          v-if="warningTrialStatus.isShow || warningFreezeStatus.isShow"
          id="qon-announcement-banner"
          as="div"
          :bg-color="warningTrialStatus.isShow ? 'violet.400' : 'amber.100'"
          px="3"
          py="2"
        >
          <mp-flex justify-content="space-between">
            <mp-flex class="centered-item">
              <mp-icon
                name="warning-circular"
                :color="warningTrialStatus.isShow ? 'white' : 'black'"
                variant="fill"
                mr="3"
              />
              <mp-box font-weight="semibold" ml="2">
                <mp-text
                  v-if="warningTrialStatus.isShow"
                  as="span"
                  color="white"
                  >{{
                    `Your Qontak Omnichannel trial access will expire in ${getExpiredTrial(
                      billingInfo.valid_until
                    )} days. To upgrade your access, please contact the company admin.`
                  }}
                </mp-text>
                <mp-text
                  v-if="warningFreezeStatus.isShow"
                  as="span"
                  color="black"
                  >Your Qontak Omnichannel access is restricted. Please consult
                  further with our support team.
                </mp-text>
                <mp-box
                  v-if="!isAgent()"
                  as="span"
                  :color="warningTrialStatus.isShow ? 'white' : 'black'"
                  text-decoration-line="underline"
                  cursor="pointer"
                  font-weight="regular"
                  @click="redirectToCustomerCare"
                  >Contact support.</mp-box
                >
              </mp-box>
            </mp-flex>
            <mp-box
              v-show="!isAgent()"
              as="div"
              cursor="pointer"
              @click="warningTrialStatus.isShow = false"
            >
              <mp-icon
                v-if="warningTrialStatus.isShow"
                name="close"
                color="white"
                variant="fill"
                mr="3"
                p="1"
              />
            </mp-box>
          </mp-flex>
        </mp-box> -->

      <!-- Banner Credit Balance -->
      <!-- <mp-box
          v-if="bannerCreditBalance.isShow"
          id="qon-announcement-balance"
          as="div"
          bg-color="amber.100"
          px="3"
          py="2"
        >
          <mp-flex justify-content="space-between">
            <mp-flex class="centered-item">
              <mp-icon
                name="warning-triangle"
                color="black"
                variant="fill"
                mr="3"
              />
              <mp-box font-weight="semibold" ml="2">
                <mp-text as="span">{{ bannerCreditBalance.message }} </mp-text> -->
      <!-- <mp-box v-if="isShowTopupButton" as="span" text-decoration-line="underline" cursor="pointer" @click="onClickBanner">Top up balance</mp-box> -->
      <!-- </mp-box>
            </mp-flex>
            <mp-box v-show="!isAgent()" as="div" @click="onClickBanner">
              <mp-button variant="outline">
                {{
                  billingInfo.status === 'expired' ||
                  billingInfo.status === 'grace'
                    ? 'Customer support'
                    : 'Top up quota'
                }}
              </mp-button>
            </mp-box>
          </mp-flex>
        </mp-box> -->
      <!-- </mp-box> -->
      <mp-flex
        direction="column"
        class="mp-wrapper"
        :height="`calc(100vh - ${
          heightBannerMaintenance +
          heightBannerUserStatus +
          heightBannerCreditBalance
        }px)`"
      >
        <HeaderComponent v-if="user" class="mp-header" />
        <mp-flex class="mp-main-body" as="main" flex="1" overflow="hidden">
          <MekariSidebar
            v-if="$auth.user"
            class="mp-sidebar"
            :banner-height-total="
              heightBannerMaintenance +
              heightBannerUserStatus +
              heightBannerCreditBalance
            "
            :menus="menuLists"
            :submenus="submenuLists"
            :has-sub-menu="submenuLists.length > 0"
            is-custom
            default-is-toggle
            :with-child="sidebarChildCondition()"
          />
          <mp-box
            as="section"
            class="mp-content"
            data-id="content"
            width="100%"
            :overflow-y="isMpContentOverflowAuto ? 'auto' : 'hidden'"
            height="100%"
          >
            <Nuxt />
            <InitComponent v-if="$auth.user" class="mp-init-component" />
            <ModalFreeze />
            <ModalPICValidation
              v-if="
                feature_flag_state?.company_validation?.state &&
                (isAdmin() || isSupervisor())
              "
            />
            <QontakCustomerCare
              v-if="$auth.user"
              class="mp-widget-customer-care"
              :is-trial-user="billing_info.is_trial"
            />
          </mp-box>
        </mp-flex>
        <!--
          /**
           * @description: This is the old native sidebar that built without mekari pixel
           * this code currently is commented, cause there is possibility the code is will be using again
           */
        -->
        <!-- <mp-flex as="main" max-height="calc(100vh - 57px)">
          <Sidebar
            v-if="$auth.user"
            :menus="menuLists"
            :has-sub-menu="submenuLists.length > 0"
          />
          <SidebarChild
            v-if="sidebarChildCondition()"
            :menus="menuLists"
            :submenus="submenuLists"
          />
          <mp-box
            as="section"
            data-id="content"
            width="100%"
            height="calc(100vh - 57px)"
            overflow-y="auto"
          >
            <mp-box min-height="calc(100vh - 132px)" background-color="white">
              <nuxt />
            </mp-box>
          </mp-box>
        </mp-flex> -->
      </mp-flex>
    </mp-box>
  </PixelWrapper>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
// import VueElementLoading from 'vue-element-loading'
import { deleteToken } from 'firebase/messaging'
// import Sidebar from './../components/layouts/main/Sidebar.vue'
// import SidebarChild from './../components/layouts/main/SidebarChild.vue'
import { MpBroadcast } from '@mekari/pixel'
import InitComponent from './../components/layouts/main/InitComponent.vue'
import MekariSidebar from '~/components/layouts/main/MekariSidebar'
import HeaderComponent from '~/components/layouts/Header'
import PixelWrapper from '~/components/pixel_wrapper/PixelWrapper'
import QontakCustomerCare from '~/components/layouts/QontakCustomerCare'
import ModalFreeze from '~/components/UI/Modal/ModalFreezeStatusAccount.vue'
import ModalPICValidation from '~/components/UI/Modal/ModalPICValidation.vue'
import BannerMaintenance from '~/components/banner/banner-maintenance/index.vue'
import BannerUserStatus from '~/components/banner/banner-user-status/index.vue'
import BannerCreditBalance from '~/components/banner/banner-credit-balance/index.vue'
import { messaging } from '@/plugins/firebase'

export default {
  name: 'LayoutsHub',
  components: {
    PixelWrapper,
    // Sidebar,
    // SidebarChild,
    MekariSidebar,
    HeaderComponent,
    InitComponent,
    QontakCustomerCare,
    // VueElementLoading,
    ModalFreeze,
    ModalPICValidation,
    BannerMaintenance,
    BannerUserStatus,
    BannerCreditBalance,
    MpBroadcast,
  },
  middleware: ['auth', 'settings'],
  data() {
    return {
      parentMenu: '',
      bannerMaintenance: {
        isShow: false,
        date: {
          from: '',
          to: '',
        },
        message: '',
      },
      bannerCreditBalance: {
        isShow: false,
        message: '',
        isChatbot: false,
      },
      bannerUserStatus: {
        isShow: false,
        status: '',
      },
      bannerInformationNewPrice: {
        isShow: false,
      },
    }
  },
  computed: {
    ...mapState('template', ['organization']),
    ...mapState('layouts', [
      'bannerMau',
      'loadingbar',
      'downtime',
      'bgColor',
      'appConfig',
      'heightBannerMaintenance',
      'heightBannerUserStatus',
      'heightBannerCreditBalance',
    ]),
    ...mapState('preferences', [
      'isInitiated',
      'feature_flag_state',
      'billing_info',
      'chatbot_subscription',
    ]),
    ...mapState('organization', ['app_type', 'permissions']),
    isMpContentOverflowAuto() {
      return this.parentMenu === 'settings' || this.parentMenu === 'finances'
    },
    // billingInfo() {
    //   return this.$store.getters['preferences/getBillingInfo']
    // },
    // bannerInfo() {
    //   if (this.appConfig?.banner_info) {
    //     const now = new Date()
    //     const list = [
    //       'January',
    //       'February',
    //       'March',
    //       'April',
    //       'May',
    //       'June',
    //       'July',
    //       'August',
    //       'September',
    //       'October',
    //       'November',
    //       'December',
    //     ]
    //     const date = {
    //       from: {
    //         month:
    //           list[new Date(this.appConfig.banner_info.date.from).getMonth()],
    //         day: String(
    //           new Date(this.appConfig.banner_info.date.from).getDate()
    //         ).padStart(2, '0'),
    //         year: new Date(
    //           this.appConfig.banner_info.date.from
    //         ).getFullYear(),
    //         time: new Date(
    //           this.appConfig.banner_info.date.from
    //         ).toLocaleTimeString(),
    //       },
    //       to: {
    //         month:
    //           list[new Date(this.appConfig.banner_info.date.to).getMonth()],
    //         day: String(
    //           new Date(this.appConfig.banner_info.date.to).getDate()
    //         ).padStart(2, '0'),
    //         year: new Date(this.appConfig.banner_info.date.to).getFullYear(),
    //         time: new Date(
    //           this.appConfig.banner_info.date.to
    //         ).toLocaleTimeString(),
    //       },
    //     }

    //     const exactHoursFrom =
    //       new Date(this.appConfig.banner_info.date.from).getHours() === 0
    //         ? date.from.time.replace('12', '00')
    //         : date.from.time
    //     const exactHoursTo =
    //       new Date(this.appConfig.banner_info.date.to).getHours() === 0
    //         ? date.to.time.replace('12', '00')
    //         : date.to.time

    //     let output
    //     output = this.appConfig.banner_info.text.replace(
    //       '{{date}}',
    //       '<b>' +
    //         date.from.day +
    //         ' ' +
    //         date.from.month +
    //         ' ' +
    //         date.from.year +
    //         ' ' +
    //         '</b>'
    //     )
    //     output = output.replace('{{from}}', '<b>' + exactHoursFrom + '</b>')
    //     output = output.replace('{{to}}', '<b>' + exactHoursTo + '</b>')

    //     let isMaintenance = false
    //     const fromDate = new Date(this.appConfig.banner_info.date.from)
    //     const toDate = new Date(this.appConfig.banner_info.date.to)

    //     if (this.appConfig.banner_info.hideFrom.length < 1)
    //       isMaintenance = true

    //     if (
    //       this.$auth.user &&
    //       this.appConfig.banner_info.hideFrom.includes(
    //         this.$auth.user.organization_id
    //       )
    //     )
    //       isMaintenance = false
    //     else isMaintenance = true

    //     if (
    //       now >=
    //         fromDate.setHours(
    //           fromDate.getHours() -
    //             this.appConfig.banner_info.date.warn_before_hours
    //         ) &&
    //       now <= toDate
    //     ) {
    //       isMaintenance = true
    //     } else {
    //       isMaintenance = false
    //     }

    //     return {
    //       date,
    //       output,
    //       isMaintenance,
    //     }
    //   } else {
    //     return ''
    //   }
    // },
    user() {
      return this.$auth.user
    },
    feature() {
      return this.$store.state.preferences.feature
    },
    currentPage() {
      const [firstName] = this.$route.name.split('-')
      return firstName || 'no_route'
    },
    menuLists() {
      const menus = []
      const menusToHide = []

      // Hide dashboard SLA Report (by billing config name)
      if (!this.billing_info.sla_dashboard_enabled)
        menusToHide.push('Dashboard')

      if (!this.chatbot_subscription.airene_resource_enabled)
        menusToHide.push('Airene resources')

      if (this.feature?.menu) {
        Object.keys(this.feature.menu).forEach((menu) => {
          if (this.feature.menu[menu].status) {
            const baseMenuData = {
              id: `${this.feature.menu[menu].title}-${this.feature.menu[menu].category}`,
              name: this.feature.menu[menu].title,
              icon: this.feature.menu[menu].icon,
              link: `/${this.feature.menu[menu].slug}`,
              isActive: this.currentPage.includes(this.feature.menu[menu].slug),
              category: this.feature.menu[menu].category,
            }

            if (!menusToHide.includes(baseMenuData.name))
              menus.push(baseMenuData)
          }
        })
      }

      return _.sortBy(menus, [
        function (menu) {
          return menu.category
        },
      ])
    },
    submenuLists() {
      const submenus = []
      const currentRoute = this.$route.name
      const submenusOfMenu = _.filter(
        this.feature.submenu,
        function (data) {
          return data.parent === this.existingPage
        }.bind({ existingPage: this.currentPage })
      )
      const splitRoute = currentRoute.split('-')
      const routeIsSettings = splitRoute[0] === 'settings'

      if (routeIsSettings && this.permissions?.security_settings) {
        const securitySubmenu = [
          {
            title: 'Security',
            slug: 'security',
            status: this.permissions?.security_settings,
            parent: 'settings',
            icon: 'chat',
            code: 'security',
            isNew: true,
          },
        ]
        const tokenSubmenuIndex = submenusOfMenu.findIndex(
          (submenu) => submenu.slug === 'token'
        )
        if (tokenSubmenuIndex)
          submenusOfMenu.splice(tokenSubmenuIndex, 0, ...securitySubmenu)
        else submenusOfMenu.push(securitySubmenu)
      }

      submenusOfMenu.forEach((submenu, index) => {
        // map child sub menu
        let childSubmenuLists = []
        if (this.feature.childsubmenu) {
          Object.keys(this.feature.childsubmenu).forEach((child) => {
            if (
              this.feature.childsubmenu[child].submenuparent === submenu.slug &&
              this.feature.childsubmenu[child].menuparent === submenu.parent
            ) {
              childSubmenuLists.push({
                ...this.feature.childsubmenu[child],
                isActive:
                  this.$route.path ===
                  `/${this.feature.childsubmenu[child].slug}`,
              })
            }
          })
          if (this.app_type === 'outbound') {
            childSubmenuLists = childSubmenuLists.filter(
              (item) => item.slug !== 'settings/token/chatbot'
            )
          }
        }

        if (submenu.status) {
          const baseSubmenusData = {
            id: `${submenu.parent}-${submenu.slug}`,
            name: submenu.title,
            status: submenu.status,
            isActive: currentRoute.split('-')[1] === submenu.slug,
            link: `/${this.$route.fullPath.split('/')[1]}/${submenu.slug}`,
            icon: submenu.icon,
            child: childSubmenuLists,
            isNew: submenu.isNew,
            index,
          }
          submenus.push(baseSubmenusData)
        }
      })
      return submenus.filter((submenu) => submenu.status)
    },
  },
  watch: {
    'bannerMaintenance.isShow': {
      handler(newValue) {
        if (!newValue)
          this.$store.commit('layouts/SET_HEIGHT_BANNER_MAINTENANCE', 0)
      },
      deep: true,
    },
    'bannerCreditBalance.isShow': {
      handler(newValue) {
        if (!newValue)
          this.$store.commit('layouts/SET_HEIGHT_BANNER_CREDIT_BALANCE', 0)
      },
      deep: true,
    },
    'bannerUserStatus.isShow': {
      handler(newValue) {
        if (!newValue)
          this.$store.commit('layouts/SET_HEIGHT_BANNER_USER_STATUS', 0)
      },
      deep: true,
    },
    'appConfig.banner_info': {
      handler(newValue) {
        if (newValue && Object.keys(newValue).length > 0) {
          const date = this.getNewDate(newValue.date)
          const message = this.getMessage(date, newValue)
          const isShow = this.getIsShow(newValue)
          this.bannerMaintenance.isShow = isShow
          this.bannerMaintenance.date = date
          this.bannerMaintenance.message = message
        } else {
          this.bannerMaintenance.isShow = false
        }
      },
      deep: true,
    },
    $route() {
      const path = this.$route.path
      const [, parentMenu] = path.split('/')
      this.parentMenu = parentMenu
    },
    billing_info: {
      handler(newValue) {
        if (newValue && newValue.status && newValue.status === 'freeze') {
          this.bannerUserStatus.isShow = true
          this.bannerUserStatus.status = 'freeze'
        }
        if (newValue && newValue.is_trial) {
          this.bannerUserStatus.isShow = true
          this.bannerUserStatus.status = 'trial'
        }
      },
      deep: true,
    },
    bannerMau: {
      handler(newValue) {
        // Exclude unused properties related to show banner
        const nameAlertToShow = { ...newValue.alerts }
        const propertiesToRemove = ['is_new_activation']
        for (const alertsName of propertiesToRemove) {
          delete nameAlertToShow[alertsName]
        }

        if (
          Object.keys(newValue).length > 0 &&
          Object.values(nameAlertToShow).some((item) => item)
        ) {
          this.bannerCreditBalance.isShow = true
          this.bannerCreditBalance.message = this.onCheckingMessage(
            newValue.alerts
          )
        } else {
          this.bannerCreditBalance.isShow = false
        }
      },
    },
    'appConfig.banner_wa_new_price_information': {
      handler(newValue) {
        if (newValue) {
          this.bannerInformationNewPrice.isShow = true
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.onFetchBannerMau()
  },
  methods: {
    getIsShow(bannerInfo) {
      const now = new Date()
      let isShow = false
      const fromDate = new Date(bannerInfo.date.from)
      const toDate = new Date(bannerInfo.date.to)

      if (bannerInfo.hideFrom.length < 1) isShow = true
      if (
        this.$auth.user &&
        bannerInfo.hideFrom.includes(this.$auth.user.organization_id)
      )
        isShow = false
      else isShow = true

      if (
        now >=
          fromDate.setHours(
            fromDate.getHours() - bannerInfo.date.warn_before_hours
          ) &&
        now <= toDate
      ) {
        isShow = true
      } else {
        isShow = false
      }
      return isShow
    },
    getMessage(date, bannerInfo) {
      const exactHoursFrom =
        new Date(bannerInfo.date.from).getHours() === 0
          ? date.from.time.replace('12', '00')
          : date.from.time
      const exactHoursTo =
        new Date(bannerInfo.date.to).getHours() === 0
          ? date.to.time.replace('12', '00')
          : date.to.time

      let message
      message = bannerInfo.text.replace(
        '{{date}}',
        '<b>' +
          date.from.day +
          ' ' +
          date.from.month +
          ' ' +
          date.from.year +
          ' ' +
          '</b>'
      )
      message = message.replace('{{from}}', '<b>' + exactHoursFrom + '</b>')
      message = message.replace('{{to}}', '<b>' + exactHoursTo + '</b>')
      return message
    },
    getNewDate(date) {
      const list = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      return {
        from: {
          month: list[new Date(date.from).getMonth()],
          day: String(new Date(date.from).getDate()).padStart(2, '0'),
          year: new Date(date.from).getFullYear(),
          time: new Date(date.from).toLocaleTimeString(),
        },
        to: {
          month: list[new Date(date.to).getMonth()],
          day: String(new Date(date.to).getDate()).padStart(2, '0'),
          year: new Date(date.to).getFullYear(),
          time: new Date(date.to).toLocaleTimeString(),
        },
      }
    },
    onCloseBannerStatus(value) {
      this.bannerUserStatus.isShow = value
      !value &&
        this.$emit('set-banner-height', {
          type: 'user-status',
          value: 0,
        })
    },
    setBannerHeight({ type, value }) {
      switch (type) {
        case 'maintenance':
          this.$store.commit('layouts/SET_HEIGHT_BANNER_MAINTENANCE', value)
          break
        case 'user-status':
          this.$store.commit('layouts/SET_HEIGHT_BANNER_USER_STATUS', value)
          break
        case 'credit-balance':
          this.$store.commit('layouts/SET_HEIGHT_BANNER_CREDIT_BALANCE', value)
          break
        default:
          break
      }
    },
    onClickBanner() {
      if (this.$store.state.preferences.feature_flag_state.self_topup.state) {
        if (
          this.billing_info.billing_enabled &&
          this.billing_info.payment_type === 'prepaid' &&
          !this.billing_info.is_trial &&
          this.billing_info.status === 'active' &&
          !this.bannerCreditBalance.isChatbot
        ) {
          this.$router.push('/finances/topup')
        } else {
          this.redirectToCustomerCare()
        }
      } else {
        this.redirectToCustomerCare()
      }
    },
    redirectToCustomerCare() {
      if (this.billing_info.is_trial) {
        window.open(
          'https://wa.me/6281287090263?&text=[Q4H-02K]%20Halo,%20saya%20sudah%20menggunakan%20akun%20trial%20dan%20ingin%20berlangganan%20Omnichanel%20Qontak'
        )
      } else {
        window.open('https://app.qontak.com/contact-support')
      }
    },
    onCheckingMessage(alerts) {
      let message = ''
      if (alerts.is_mcc_over_soon && alerts.is_muv_over_soon) {
        message =
          'Your MUV/MCC quota is approaching the usage limit of 40%. Immediately Top Up your quota via Self Top Up.'
        if (this.isAgent())
          message = 'Your MUV/MCC quota is approaching the usage limit of 40%.'
      } else if (alerts.is_mcc_over_soon) {
        message =
          'Your MCC quota is approaching the usage limit of 40%. Immediately Top Up your quota via Self Top Up.'
        if (this.isAgent())
          message = 'Your MCC quota is approaching the usage limit of 40%.'
      } else if (alerts.is_muv_over_soon) {
        message =
          'Your MUV quota is approaching the usage limit of 40%. Immediately Top Up your quota via Self Top Up.'
        if (this.isAgent())
          message = 'Your MUV quota is approaching the usage limit of 40%.'
      } else if (alerts.is_mcc_over && alerts.is_muv_over) {
        message =
          "You're out of quota. To continue your access, please top up your balance."
      } else if (alerts.is_mcc_over) {
        message =
          "You're out of WhatsApp conversation quota. To receive or send WhatsApp messages, please top up your balance."
      } else if (alerts.is_muv_over) {
        message =
          "You're out of <b>Unique visitor quota.</b> To keep your access, please top up your balance."
      } else if (alerts.is_package_inactive) {
        message =
          'Your package is expired. To continue your access, please contact our support team.'
      } else if (alerts.is_chatbot_ai_running_out) {
        this.bannerCreditBalance.isChatbot = true
        message =
          "You're running out of <b>chatbot AI dialog.</b> To use AI for your chatbot, top up your quota by contacting our support team."
      } else if (alerts.is_chatbot_ai_run_out) {
        this.bannerCreditBalance.isChatbot = true
        message =
          "You're out of <b>chatbot AI dialog.</b> To use AI for your chatbot, top up your quota by contacting our support team."
      } else if (alerts.is_has_debt) {
        message =
          'Your additional balance is now below Rp0. Top up your balance to receive or send WhatsApp messages.'
      } else if (alerts.is_new_activation) {
        message = ''
      } else {
        message = ''
      }
      return message
    },
    async onFetchBannerMau() {
      if (this.$auth.user) {
        await this.$store
          .dispatch('layouts/getBannerMau', {
            organization_id: this.$auth.user.organization_id,
          })
          .catch((err) => {
            const errorCode = err?.error?.code || null
            if (errorCode === 401 && !this.isSuperAdmin()) {
              setTimeout(async () => {
                if (
                  this.browser_type !== 'Safari' &&
                  localStorage.getItem('subscribe_token')
                ) {
                  this.removeCurrentSubsToken()
                  // this.$fire.messaging.deleteToken(localStorage.getItem('subscribe_token'))
                  if ((this.isAgent() || this.isSupervisor()) && this.is_online)
                    this.$nuxt.$emit('onLoggedOut')
                  await deleteToken(
                    messaging,
                    localStorage.getItem('subscribe_token')
                  )
                  await this.$auth.logout()
                }
                location.reload()
              }, 3000)
            }
          })
      }
    },
    async removeCurrentSubsToken() {
      await this.$store
        .dispatch('fcm/removePreviousFcmToken', {
          token: localStorage.getItem('subscribe_token'),
        })
        .then((message) => {
          localStorage.removeItem('subscribe_token')
        })
        .catch((err) => {
          throw err
        })
      localStorage.removeItem('subscribe_token')
    },
    sidebarChildCondition() {
      const toggleChatbotAI = this.appConfig?.is_display_chatbot_usage

      if (toggleChatbotAI) {
        return this.$auth.user && this.submenuLists.length > 0
      } else {
        return (
          this.$auth.user &&
          this.submenuLists.length > 0 &&
          !this.$route.name.includes('finances-package_usage')
        )
      }
    },
    handleCloseBannerInformation() {
      this.bannerInformationNewPrice.isShow = false
    },
    handleClickLinkBannerInformation() {
      window.open(
        'https://help-center.qontak.com/hc/id/articles/10265853268121-Bagaimana-Cara-Mengelola-Package-Usage-Conversation'
      )
    },
  },
}
</script>
